import React from 'react';
//import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./store";
import App from './App';

import reportWebVitals from './reportWebVitals';

//ReactDOM.createRoot(document.getElementById('root')).render(

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
	<Provider store={store}>
          <App />
        </Provider>    
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
