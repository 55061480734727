import axios from 'axios';
import * as cookie from "../utils/cookie/Cookies";
// const BASE_URL = 'http://49.50.173.63';
//const BASE_URL = 'http://localhost';
//const BASE_URL = 'https://api.sidrec.go.kr:8087'

// 상용 서버
const BASE_URL = 'https://api.sidrec.go.kr:8087';

// 개발 서버
// const BASE_URL = 'http://211.217.67.40:22088';


const defaultAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Method" : "GET, POST, PUT, PATCH, DELETE, OPTIONS",
        "Access-Control-Allow-Headers" : "Content-Type, Authorization"
    },
    withCredentials: true
});
defaultAxios.interceptors.response.use((response) => {
    if ('code' in response.data && parseInt(response.data['code'].toString(), 10) === 601) {
        alert('세션이 만료되었습니다. 다시 로그인해 주세요.');
        window.localStorage.clear();
        cookie.removeCookie("access_token");
        cookie.removeCookie("memberNo");
        cookie.removeCookie("memberId");
        cookie.removeCookie("memberName");
        cookie.removeCookie("gubun");
        cookie.removeCookie("authList");
        window.location.href = "/";
        return response;
    }
    return response;
});

export default defaultAxios;

export const axiosFiles = axios.create({
    baseURL: BASE_URL,
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Method" : "GET, POST, PUT, PATCH, DELETE, OPTIONS",
        "Access-Control-Allow-Headers" : "Content-Type, Authorization"
    },
    responseType: "blob",
    withCredentials: true
});

export const axiosExpress = axios.create({
    baseURL: "http://223.130.172.181:3001",
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Method" : "GET, POST, PUT, PATCH, DELETE, OPTIONS",
        "Access-Control-Allow-Headers" : "Content-Type, Authorization"
    },
    withCredentials: true
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Access-Control-Allow-Headers" : "Content-Type",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
     },
    withCredentials: true
});
