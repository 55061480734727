import * as React from 'react';
import { Link } from 'react-router-dom';
 
function HeaderMenu({ index, name, path, isActive }) {
  return isActive === true ? (
    <Link to={path} key={ index } className="onea on"
      //style={ auth === true ? {display: ""} : {display: "none"} }
    >
      <span>{ name }</span>
    </Link>
  ) : (
    <Link to={path} key={ index } className="onea"
      //style={ auth === true ? {display: ""} : {display: "none"} }
    >
      <span>{ name }</span>
    </Link>
  );
}

export default HeaderMenu;