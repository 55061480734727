// export const BASE_URL = "http://49.50.173.63";

export const BASE_URL = "http://192.168.50.66:8080";

export const apiURI = {
  // login: "/api/react_login.json",
  login: "/login/loginAction",
  logout: "/login/logout",
  signUp: "",
  dashboard: "",
  dataUpload: {
    analysis: {
      registView: "/dataAnalysis/registView",
      regist: "/dataAnalysis/regist",
      tableRegist: "/dataAnalysis/tableRegist",
      recodeEdit: "/dataAnalysis/recodeEdit",
      list: "/dataAnalysis/list",
    },
  },
  data: {
    excel: {
      list: "/excelAnalysis/list",
      detail: "/excelAnalysis/detail"
    },
    hub: {
      list: "/ssis/hub",
      download: "/ssis/hubExcel",
    },
    crawling: {
      list: "/crawling/list",
      download: "/crawling/excel",
      view: "/crawling/view",
      edit: "/crawling/modify"
    },
    public: {
      list: "/OpenApi/ReceiveList",
      download: "/OpenApi/ReceiveExcel"
    },
  },
  openAPI: {
    api: {
      list: "/openApi/list",
      regist: "/openApi/regist",
      modify: "/openApi/modify",
      view: "/openApi/view",
      modifyUseYn: "/openApi/modifyUseYn",
    },
    user: {
      list: "/openApi/userList",
      userModifyUseYn: "/openApi/userModifyUseYn",
      regist: "/openApi/userRegist",
      userDoubleCheck: "/openApi/userDoubleCheck",
      keyCreate: "/openApi/userApiCreate",
      view: "/openApi/userView",
      modify: "/openApi/userModify",
      download: "/openApi/userExcel",
      userPassModify: "/openApi/userPassModify",
    },
  },
  work: {
    news: {
      list: "/newsletter/list",
      regist: "/newsletter/regist",
      view: "/newsletter/view",
      modify: "/newsletter/modify",
      delete: "/newsletter/delete",
    },
    patient: {
      list: "/ssis/patientList",
      view: "/ssis/patientView",
      upload: "/ssis/patientUpload",
      download: "/ssis/patientListDown"
    },
    indepth: {
      list: "/ssis/indepthList",
      upload: "/ssis/indepthUpload",
      modify: "/ssis/indepthModify",
      delete: "/ssis/indepthDelete",
      view: "/ssis/indepthView",
      download: "/ssis/indepthExcel"
    },
    contact: {
      list: "/contactTracing/contactList",
      view: "/contactTracing/contactView",
      download: "/contactTracing/contactListDown"
    },
    untact: {
      list: "/untacts/untactList",
      view: "/untacts/untactView",
      download: "/untacts/untactListDown"
    },
  },
  contents: {
    screen: {
      list: "/screen/list",
      view: "/screen/view",
      regist: "/screen/regist",
      modify: "/screen/modify",
      delete: "/screen/delete",
    },
    board: {
      list: "/board/list",
      regist: "/board/regist",
      view: "/board/view",
      modify: "/board/modify",
      delete: "/board/delete",
      doubleCheck: "/board/doubleCheck",
    },
    bedbug: {
      list: "/bedbug/list",
      view: "/bedbug/detail",
      delete: "/bedbug/delete",
      download: "/bedbug/listDown",
    }
  },
  system: {
    manager: {
      list: "/manager/list",
      regist: "/manager/regist",
      modify: "/manager/modify",
      view: "/manager/view",
      delete: "/manager/delete",
      authRegist: "/authority/regist",
      code: "/manager/codeList",
      download: "/statistics/listExcel",
    },
    code: {
      list: "/code/list",
      view: "/code/view",
      regist: "/code/regist",
      modify: "/code/modify",
      delete: "/code/delete",
      groupList: "/code/groupList",
      groupView: "/code/groupView",
      groupRegist: "/code/groupRegist",
      groupModify: "/code/groupModify",
      groupDelete: "/code/groupDelete",
    },
    connect: {
      list: "/connect/list",
      regist: "/connect/regist",
      modify: "/connect/useUpdate",
      delete: "/connect/delete",

    },
    Permissions: {
      regist: "/authority/regist",
      view: "/authority/view",
    },
    survey: {
      list: "/satisfySurvey/groupList",
      add: "/satisfySurvey/groupRegist",
      view: "/satisfySurvey/groupView",
      edit: "/satisfySurvey/groupUpdate",
      delete: "/satisfySurvey/groupDelete"
    }
  }
  // indepth: "/ssis/indepth",
};
