import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from '../context/AuthProvider';

import Loader from "../components/loading/Loading";
import { productInputs, userInputs } from "../data/FormSource";
import {Bed} from "@mui/icons-material";

// Main Door
const MainDoor = React.lazy(() => import('../page/info/Info'))

// Containers
const Login = React.lazy(() => import('../page/login/Login'))
//const Dashboard = React.lazy(() => import('../page/dashboard/Dashboard'))
const Dashboard = React.lazy(() => import('../page/dashboard/IndexDashBoard'))

// 데이터 분석
const AlsExcel = React.lazy(() => import('../page/analysis/IndexExcel'))
const AlsTableUpload = React.lazy(() => import('../page/analysis/TableUpload'))
const AlsExcelData = React.lazy(() => import('../page/analysis/ExcelData'))
const AlsDataComplete = React.lazy(() => import('../page/analysis/DataComplete'))
const AlsDataAnalysis = React.lazy(() => import('../page/analysis/DataAnalysis'))

// 데이터 관리 - 엑셀
const ControlExcel = React.lazy(() => import("../page/controlls/excel/List"));
const ExcelView = React.lazy(() => import("../page/controlls/excel/View"));


// 데이터 관리 - 허브
const HubMng = React.lazy(() => import('../page/controlls/hub/List'))
const HubView = React.lazy(() => import('../page/controlls/hub/View'))
const HubEdit = React.lazy(() => import('../page/controlls/hub/Edit'))
const HubAdd = React.lazy(() => import('../page/controlls/hub/Add'))

// 데이터 관리 - 크롤링
const ControlCrawling = React.lazy(() => import("../page/controlls/crawling/List"));
const ControlCrawlingView = React.lazy(() => import("../page/controlls/crawling/View"));
const ControlCrawlingEdit = React.lazy(() => import("../page/controlls/crawling/Edit"));


const PatientSupport = React.lazy(() => import("../page/controlls/patientSupport/List"));

const PublicData = React.lazy(() => import("../page/controlls/publicData/List"));

// OPEN API - API
const ApiManage = React.lazy(() => import("../page/openapi/manage/List"));
const ApiAdd = React.lazy(() => import("../page/openapi/manage/Add"));
const ApiEdit = React.lazy(() => import("../page/openapi/manage/Edit"));

// OPEN API - user
const ApiUsers = React.lazy(() => import("../page/openapi/users/List"));
const UserAdd = React.lazy(() => import("../page/openapi/users/Add"));
const UserEdit = React.lazy(() => import("../page/openapi/users/Edit"));

// 컨텐츠관리 - 화면관리
const DisplayMng = React.lazy(() => import("../page/contens/display/List"));
const DisplayAdd = React.lazy(() => import("../page/contens/display/Add"));
const DisplayEdit = React.lazy(() => import("../page/contens/display/Edit"));
const DisplayView = React.lazy(() => import("../page/contens/display/View"));

// 콘텐츠 관리 - 게시판 관리
const BoardMng = React.lazy(() => import("../page/contens/board/List"));
const BoardAdd = React.lazy(() => import("../page/contens/board/Add"));
const BoardEdit = React.lazy(() => import("../page/contens/board/Edit"));
const BoardView = React.lazy(() => import("../page/contens/board/View"));
const PostsList = React.lazy(() => import("../page/contens/board/Posts"));
const GalleryList = React.lazy(() => import("../page/contens/board/Gallery"));
const PostsAdd = React.lazy(() => import("../page/contens/board/PostsAdd"));
const PostsEdit = React.lazy(() => import("../page/contens/board/PostsEdit"));
const PostsView = React.lazy(() => import("../page/contens/board/PostsView"));

// 빈대 관리
const BedBugList = React.lazy(() => import("../page/contens/bedBug/List"));
const BedBugView = React.lazy(() => import("../page/contens/bedBug/View"));

// 확진자정보분석 - 소식지
const NewsMng = React.lazy(() => import("../page/work/news/List"));
const NewsAdd = React.lazy(() => import("../page/work/news/Add"));
const NewsEdit = React.lazy(() => import("../page/work/news/Edit"));

// 확진자정보분석 - 확진자대장
const ConfirmMng = React.lazy(() => import("../page/work/confirmed/List"));
const ConfirmView = React.lazy(() => import("../page/work/confirmed/View"));
const ConfirmAdd = React.lazy(() => import("../page/work/confirmed/Add"));

// 확진자정보분석 - 사망자대장
const DeathMng = React.lazy(() => import("../page/work/death/List"));
const DeathView = React.lazy(() => import("../page/work/death/View"));

// 확진자정보분석 - 심층역학
const IndepthMng = React.lazy(() => import("../page/work/indepth/List"));
const IndepthView = React.lazy(() => import("../page/work/indepth/View"));
const IndepthEdit = React.lazy(() => import("../page/work/indepth/Edit"));
const IndepthAdd = React.lazy(() => import("../page/work/indepth/Add"));

// 확진자정보분석 - 집단감염
const ContactMng = React.lazy(() => import("../page/work/contact/List"));
const ContactView = React.lazy(() => import("../page/work/contact/View"));

// 확진자정보분석 - 비대면목록
const UntactMng = React.lazy(() => import("../page/work/untact/List"));
const UntactView = React.lazy(() => import("../page/work/untact/View"));

// 시스템관리 - 관리자
const AdminMng = React.lazy(() => import("../page/system/admin/List"));
const AdminAdd = React.lazy(() => import("../page/system/admin/Add"));
const AdminEdit = React.lazy(() => import("../page/system/admin/Edit"));

// 시스템관리 - 권한 관리
const PermissionMng = React.lazy(() =>
  import("../page/system/permission/List")
);

// 시스템관리 - 코드 관리
const CodeMng = React.lazy(() => import("../page/system/code/List"));
const CodeAdd = React.lazy(() => import("../page/system/code/Add"))
const CodeEdit = React.lazy(() => import("../page/system/code/Edit"))
const CodeGroupMng = React.lazy(() => import("../page/system/code/GroupList"))
const CodeGroupAdd = React.lazy(() => import("../page/system/code/GroupAdd"))
const CodeGroupEdit = React.lazy(() => import("../page/system/code/GroupEdit"))

// 시스템관리 - 접속 관리
const AccessMng = React.lazy(() => import("../page/system/access/List"));

// 시스템관리 - GDB 관리
const GdbMng = React.lazy(() => import("../page/system/gdb/List"));

// 시스템관리 - 대시보드 관리
const DashMng = React.lazy(() => import("../page/system/dash/DashIndex"));

// 시스템관리 - 통계 및 현황
const StateMng = React.lazy(() => import("../page/system/statics/StateIndex"));
const StateMng1 = React.lazy(() => import("../page/system/statics1/StateIndex1"));


// 시스템관리 - 로그관리
const LogMng = React.lazy(() => import("../page/system/log/LogIndex"));

// 시스템관리 - 성과관리
const PerformanceMng = React.lazy(() => import("../page/system/performance/PerformanceIndex"));
const PerformanceSurveyView = React.lazy(() => import("../page/system/performance/SurveyView"));
const SurveyMng = React.lazy(() => import("../page/system/survey/List"));
const SurveyAdd = React.lazy(() => import("../page/system/survey/Add"));
const SurveyEdit = React.lazy(() => import("../page/system/survey/Edit"));
const SurveyView = React.lazy(() => import("../page/system/survey/View"));

// 메신저
const MessengerList = React.lazy(() => import("../page/messenger/List"));
const MessengerView = React.lazy(() => import("../page/messenger/View"));

// AI
const SIMIQView = React.lazy(() => import("../page/simiq/View"));

const NotFound = React.lazy(() => import('../components/utils/error/NotFound'))

const loading = (
  <div className="pt-3 text-center">
    <Loader />
  </div>
)

const Router = () => {
  return (
    <Suspense fallback={loading}>
      <AuthProvider>

        <Routes>
          {/* public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/analUpload" element={<AlsTableUpload />} />
          <Route path="/analData" element={<AlsExcelData />} />
          <Route path="/analComplete" element={<AlsDataComplete />} />
          <Route path="/analAnalysis" element={<AlsDataAnalysis />} />

          <Route path="/contExcel">
            <Route index element={<ControlExcel />} />
            <Route path="add" element={<AlsExcel />} />
            <Route path="view/:idx" element={<ExcelView />} />
          </Route>

          <Route path="/contHub">
            <Route index element={<HubMng />} />
            <Route path="view/:idx" element={<HubView />} />
            <Route path="edit/:idx" element={<HubEdit />} />
            <Route path="hubAdd" element={<HubAdd inputs={userInputs} />} />
          </Route>

          <Route path="/contCrawling">
            <Route index element={<ControlCrawling />} />
            <Route path="view/:idx" element={<ControlCrawlingView />} />
            <Route path="edit/:id" element={<ControlCrawlingEdit />} />
          </Route>

          <Route path="/patientSupport" element={<PatientSupport />} />

          <Route path="/systPublicData" element={<PublicData />} />

          <Route path="/systApiMng">
            <Route index element={<ApiManage />} />
            <Route path="add" element={<ApiAdd />} />
            <Route path="edit/:id" element={<ApiEdit />} />
          </Route>

          <Route path="/systApiUser">
            <Route index element={<ApiUsers />} />
            <Route path="add" element={<UserAdd />} />
            <Route path="edit/:id" element={<UserEdit />} />
          </Route>

          <Route path="/consDisplay">
            <Route index element={<DisplayMng />} />
            <Route path="edit/:id" element={<DisplayEdit />} />
            <Route path="add" element={<DisplayAdd />} />
            <Route path="view/:id" element={<DisplayView />} />
          </Route>

          <Route path="/consBoard">
            <Route index element={<BoardMng />} />
            <Route path="add" element={<BoardAdd />} />
            <Route path="edit/:id" element={<BoardEdit />} />
            <Route path="view/:id" element={<BoardView />} />
            <Route path="posts/:id" element={<PostsList />} />
            <Route path="gallery/:id" element={<GalleryList />} />
            <Route path="posts/:id/add" element={<PostsAdd />} />
            <Route path="posts/:id/edit/:postsId" element={<PostsEdit />} />
            <Route path="posts/:id/view/:postsId" element={<PostsView />} />
          </Route>

          <Route path="/consBedBug">
            <Route index element={<BedBugList />} />
            <Route path="view/:id" element={<BedBugView />} />
          </Route>

          <Route path="/consNewsMng">
            <Route index element={<NewsMng />} />
            <Route path="edit/:id" element={<NewsEdit />} />
            <Route path="add" element={<NewsAdd />} />
          </Route>

          <Route path="/workConfirmMng" element={<ConfirmMng />} />
          <Route path="/workConfirmView" element={<ConfirmView />} />
          <Route path="/workConfirmAdd" element={<ConfirmAdd />} />

          <Route path="/workDeathMng" element={<DeathMng />} />
          <Route path="/workDeathView" element={<DeathView />} />
          {/* <Route path="/workConfirmAdd" element={<ConfirmAdd />} /> */}

          <Route path="/workIndepthMng">
            <Route index element={<IndepthMng />} />
            <Route path="view/:params" element={<IndepthView />} />
            <Route path="edit/:params" element={<IndepthEdit />} />
            <Route
              path="inDepthAdd"
              element={<IndepthAdd inputs={userInputs} />}
            />
          </Route>

          <Route path="/workStateMng">
            <Route index element={<StateMng />} />
          </Route>

          <Route path="/workStateMng1">
            <Route index element={<StateMng1 />} />
          </Route>

          <Route path="/workGdbMng">
            <Route index element={<GdbMng />} />
          </Route>

          <Route path="/workContactMng"> {/* 집단발생 */}
            <Route index element={<ContactMng />} />
            <Route path="view/:id" element={<ContactView />} />
          </Route>

          <Route path="/workUntactMng"> {/* 비대면 일일 조회 */}
            <Route index element={<UntactMng />} />
            <Route path="view/:id" element={<UntactView />} />
          </Route>

          <Route path="/systAdminMng">
            <Route index element={<AdminMng />} />
            <Route path="edit/:params" element={<AdminEdit />} />
            <Route
              path="add"
              element={<AdminAdd />}
            />
          </Route>

          <Route path="/systAuthMng">
            <Route index element={<PermissionMng />} />
          </Route>

          <Route path="/systCodeMng">
            <Route index element={<CodeMng />} />
            <Route path="edit/:params" element={<CodeEdit />} />
            <Route
              path="add"
              element={<CodeAdd />}
            />
            <Route path="group" element={<CodeGroupMng />} />
            <Route path="groupEdit/:params" element={<CodeGroupEdit />} />
            <Route
              path="groupAdd"
              element={<CodeGroupAdd />}
            />
          </Route>

          <Route path="/systAccessMng">
            <Route index element={<AccessMng />} />
          </Route>





          <Route path="/systDashMng">
            <Route index element={<DashMng />} />
          </Route>



          <Route path="/systLogMng">
            <Route index element={<LogMng />} />
          </Route>

          <Route path="/systPerforMng">
            <Route index element={<PerformanceMng />} />
            <Route path="view/:id" element={<PerformanceSurveyView />} />
          </Route>
          <Route path="/systSurveyMng">
            <Route index element={<SurveyMng />} />
            <Route path="add" element={<SurveyAdd />} />
            <Route path="edit/:id" element={<SurveyEdit />} />
            <Route path="view/:id" element={<SurveyView />} />
          </Route>

          <Route path="/messenger/">
            <Route path="list" element={<MessengerList />} />
            <Route path="view/:roomId" element={<MessengerView />} />
          </Route>

          <Route path="/simiq/">
            <Route path="view/:roomId" element={<SIMIQView />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Suspense>

  )
}

export default Router
