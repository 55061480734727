import * as React from 'react';
import { Route, Routes } from "react-router-dom";

import * as cookie from './utils/cookie/Cookies';
import MainDoor from './page/info/Info'
import Login from './page/login/Login';
import DefaultLayout from './layouts/DefaultLayout';

function App() {
  // cookie.setCookie("access_token", "75eb0401-6eec-49d5-88fd-942239e7c42f")
  // cookie.setCookie("memberId", "domin")
  const access_token = cookie.getCookie("access_token");
  console.log(access_token);
  const pathname = window.location.pathname;
  console.log(pathname)

  if (pathname === "/") {
    return <MainDoor />
  }

  if (!access_token) {
    return <Login />
  }

  return (
    <DefaultLayout />
  );
}

export default App
