import * as React from 'react';

import Header from './Header';
import Router from '../route/Router';
import Footer from './Footer';


const DefaultLayout = () => {
    const pathname = window.location.pathname;
    console.log(pathname.substring(0,10));
    return (
        <>
            {!((pathname.substring(0,10) === "/messenger") || (pathname.substring(0,6) === "/simiq")) ? (
                <div id="wrapper" style={{ minWidth: 1800 }}>
                    {/* <!--// header --> */}
                    <div className="header">
                        <Header />
                    </div>
                    {/* <!-- header //--> */}

                    {/* <!--// content --> */}
                    <Router />
                    {/* <!-- content //--> */}
                    {/* <Footer /> */}
                </div>
            ) : (
                <Router />
            )}
        </>
    )
}

export default DefaultLayout
