import { Translate } from "@mui/icons-material";
import * as React from "react";
import { useLocation, Link } from 'react-router-dom';

const Info = () => {
  return (
      <>
        <div className="MainDoorBody">
          <div style={{width: "100%", height: "calc(100vh * 0.0423)", backgroundColor: "#F6F5FB", position: "absolute", top: "0"  }}>
            <img src="images/header_logo.png" alt="" style={{position: "absolute", left: "4%", top: "50%", transform: "translate(0, -50%)" , width: "150px", height: "27px"}}/>
          </div>

          <div className="MainDoorBox">
            <div style={{position: "relative", marginTop: "calc(100vh * 0.119)"}}>
              <img src="images/main_text_logo.png" style={{width: "25%"}}/>
              <img src="images/main_logo_seoul_text.png" style={{width: "5.5%", position: "absolute", top: "9%", left: "44%"}}/>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: "calc(100vh * 0.0604)"}}>
              <a href="https://sims.sidrec.go.kr/login" style={{marginLeft: 0}} className="zoom-in-effect">
                <div style={{position: "relative", overflow: "hidden", borderRadius: "calc(100vh * 0.289)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)", /* 그림자 효과 추가 */
                  width: "calc(100vh * 0.289)", height: "calc(100vh * 0.289)", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src="images/index_link_sims.png" alt="" className="zoom-in-effect-link"/>
                  <img src="images/arrow_up_forward.png" alt="" style={{ position:"absolute", left: "50%", top: "33%", transform: "translate(-50%, -50%)", width: "10%"}} />
                  <p className="info_link_small_text">감염병 데이터 관리</p>
                  <p className="info_link_large_text info_link_large_text_sims">SIMS</p>
                </div>
              </a>
              <a href="https://info.sidrec.go.kr/" target="_blank" className="zoom-in-effect">
                <div style={{position: "relative", overflow: "hidden", borderRadius: "calc(100vh * 0.289)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)", /* 그림자 효과 추가 */
                  width: "calc(100vh * 0.289)", height: "calc(100vh * 0.289)", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src="images/index_link_noc19.png" alt="" className="zoom-in-effect-link"/>
                  <img src="images/arrow_up_forward.png" alt="" style={{ position:"absolute", left: "50%", top: "33%", transform: "translate(-50%, -50%)", width: "10%"}} />
                  <p className="info_link_small_text">병상 배정 관리</p>
                  <p className="info_link_large_text info_link_large_text_noc19">병상 시스템</p>
                </div>
              </a>
              <a href="https://sidrec.go.kr/" target="_blank" className="zoom-in-effect">
                <div style={{position: "relative", overflow: "hidden", borderRadius: "calc(100vh * 0.289)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)", /* 그림자 효과 추가 */
                  width: "calc(100vh * 0.289)", height: "calc(100vh * 0.289)", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src="images/index_link_sidrec.png" alt="" className="zoom-in-effect-link"/>
                  <img src="images/arrow_up_forward.png" alt="" style={{ position:"absolute", left: "50%", top: "33%", transform: "translate(-50%, -50%)", width: "10%"}} />
                  <p className="info_link_small_text">감염병 정보 전달</p>
                  <p className="info_link_large_text info_link_large_text_sidrec">감염병관리지원단<br />누리집</p>
                </div>
              </a>
            </div>

            {/*}
          <div className="logo" style={{ height: "78px" }}><img src="images/index_00.jpg" height={"100%"} /></div>
          <div className="desc" style={{ margin: "auto auto", marginTop: "100px", height: "20px" }}><img src="images/index_01.jpg" height={"100%"} /></div>
          <div className="link" style={{ margin: "0 auto", marginTop: "5rem", height: "295px", display: "grid", padding: "0 25%" }}>
            <a href="https://sidrec.go.kr/"><img src="images/index_link_00.png" alt="" style={{ marginTop: "0px", marginRight: "80px" }} /></a>
            <a href="https://sims.sidrec.go.kr/dashboard"><img src="images/index_link_01.png" alt="" style={{ marginTop: "40px", marginRight: "80px" }} /></a>
            <a href="https://info.sidrec.go.kr/main"><img src="images/index_link_02.jpg" alt="" style={{ height: "70%" }} /></a>
          </div>
          {*/}
          </div>

          <div style={{width: "100%", position: "absolute", bottom: "30px"}}>
            <p style={{color: "#646464", fontSize: "12px", fontWeight: "300", marginBottom: "6px"}}>서울특별시 감염병관리과 04524 서울 중구 세종대로 110 3층 (태평로1가, 서울특별시청) 02-120 | 시스템관련: 02-2133-9519</p>
            <p style={{color: "#646464", fontSize: "12px", fontWeight: "3 00"}}>ⓒ Seoul Metropolitan Government all rights reserved.</p>
          </div>
        </div>
      </>
  )
}

export default Info;
