import React from "react";

const InfoModal = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = React.useState(false);  
  
  React.useImperativeHandle(ref, () => ({
    showAlert() {  
        openModal();    
    }
  }));  

  const [inputData, setInputData] = React.useState({
    //memberToken : cookie.getCookie("access_token"),
    gubun : "",
    memberId : "",
    passwd : "",
    passwordChk: "",
    memberName : "",
    state : "",
    authNo: "",
    deptNo : "",
    posNo : "",
    mobileNo : "",
    remark: "",
  });

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const saveTableInfo = () => {
    let checkVal = handleValidation();
    if(checkVal){
        if (window.confirm("수정 하시겠습니까?")) {
        }
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    
    if(inputData.passwd.length == 0){
        alert("비밀번호를 입력해주세요.");
        formIsValid = false;
        return false;
    }

    let check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;
    if(!check.test(inputData.passwd)) {
        alert("10자이상 숫자, 영문자, 특수문자를 조합하여야 합니다.");
        return false;
    }
    
    if(inputData.passwd !== inputData.passwordChk){
        alert("비밀번호가 일치하지 않습니다.");
        formIsValid = false;
        return false;
    }      

    return formIsValid;
  }

  const handleInput = (e) => {
    setInputData(prev => ({
        ...prev,
        [e.target.name] : e.target.value
    }))
  }
  

  return (
    <div className={modalOpen ? 'openModal modal' : 'modal'}>
        <div id="excel_reg" className="popup">
        <div className="pop_back"></div>
        <div className="popup_in">
            <div className="pop_cont">
                <div className="pop_close">
                    <img src="/img/ico_close.png" alt="닫기" onClick={closeModal} style={{cursor: "pointer"}}/>
                </div>
                <div className="pop_tit">정보수정</div>
                <div className="pop_table_wrap">
                                
                <table className="pop_table" style={{marginTop: "10px"}} >
                    <colgroup>
                        <col width="30%" />
                        <col width="*" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>아이디</th>
                        <td>
                            root
                        </td>
                    </tr>
                    <tr>
                        <th>이름</th>
                        <td>
                            관리자
                        </td>
                    </tr>
                    <tr>
                        <th>비밀번호</th>
                        <td>
                            <input type="text" 
                                   id="passwd" 
                                   className="input wfull" 
                                   autoFocus
                                   //onChange={(e) => setEntitle(e.target.value)} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>비밀번호확인</th>
                        <td>
                            <input type="text" 
                                   id="passwordChk" 
                                   className="input wfull" 
                                   autoFocus
                                   //onChange={(e) => setEntitle(e.target.value)} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td>
                            <input type="text" 
                                   id="mobileNo" 
                                   className="input wfull" 
                                   autoFocus
                                   //onChange={(e) => setEntitle(e.target.value)} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>메모</th>
                        <td>
                            <input type="text" 
                                   id="remark" 
                                   className="input wfull" 
                                   autoFocus
                                   //onChange={(e) => setEntitle(e.target.value)} 
                            />
                        </td>
                    </tr>
                    

                    
                    </tbody>
                </table>

                </div>
                <div className="btn_area">
                    <div className="btn btn-gray w100 mar" onClick={closeModal} style={{cursor: "pointer"}}>취소</div>
                    <div className="btn btn-gray w100" onClick={saveTableInfo} style={{cursor: "pointer"}}>저장</div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
});

export default InfoModal;