import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import * as cookie from "../utils/cookie/Cookies";
import HeaderMenu from "../components/menu/HeaderMenu";
import axios from "../api/Axio";
import { apiURI } from "../api/Api-endpoint";

import InfoModal from "../components/modals/main/InfoModal";

const Header = () => {
  // URL의 path값을 받아올 수 있다.
  const pathName = useLocation().pathname;
  const menuStr = pathName.substring(1, 5);
  const childRef = React.useRef();

  const [showNav, setShowNav] = React.useState(false);
  const [menuAuth, setManuAuth] = React.useState([]);

  const LOOUT_URL = "/login/logout";

  const [params, setParams] = React.useState({
    memberToken : cookie.getCookie("access_token"),
    memberId : cookie.getCookie("memberId"),
  });

  const openAddHandle = (e) =>{
    e.preventDefault();
    childRef.current.showAlert();
  }

  React.useEffect(() => {
    console.log("권한",params)
    getAuth(params);


    
  },[]);


  const getAuth = async (params) => {
    const view_url = "/login/loginAuth";
    axios.post(view_url, params)
    .then(function (response) {
      let rsCode = response?.data?.code;
      let rsMassage = response?.data?.message;
      let authList = response?.data?.auth;
      console.log("Menu",authList);

      if (rsCode === 200) {
        const colList = [];
        if(authList.length > 0){
          for(let i = 0; i < authList.length; i++) {
            if(authList[i].upperMenuNo === 0){
              let mNo = authList[i].upperMenuNo;
              let m1 = authList[i].menuId.substring(0, 4);

              var obj = {};
                obj.name = authList[i].menuName;
                obj.path = authList[i].menuUrl;
                obj.menu = m1;
                colList.push(obj);
/*
              if(m1 === "dash" || m1 === "anal"){
                var obj = {};
                obj.name = authList[i].menuName;
                obj.path = authList[i].menuUrl;
                obj.menu = m1;
                colList.push(obj);
              }else if(m1 === "cont"){
                var obj = {};
                obj.name = "데이터관리";
                obj.path = "/contExcel";
                obj.menu = m1;
                colList.push(obj);
//              }else if(m1 === "open"){
//                var obj = {};
//                obj.name = "OPEN API";
//                obj.path = authList[i].menuUrl;
//                obj.menu = m1;
//                colList.push(obj);
              }else if(m1 === "work"){
                var obj = {};
                obj.name = "확진자정보분석";
                obj.path = "/consNewsMng";
                obj.menu = m1;
                colList.push(obj);
              }else if(m1 === "cons"){
                var obj = {};
                obj.name = "컨텐츠관리";
                obj.path = "/consDisplay";
                obj.menu = m1;
                colList.push(obj);
              }else if(m1 === "syst"){
                var obj = {};
                obj.name = "시스템관리";
                obj.path = "/systAdminMng";
                obj.menu = m1;
                colList.push(obj);
              }
*/
            }
          }
        }
        setManuAuth(colList);
        //console.log(colList);
      }else{
        alert("권한이 없습니다.");
        logout();
      }
    })
    .catch(function (error) {
      alert("권한 오류입니다.");
    });



  }

  const logout = async (e) => {
    e.preventDefault();
    console.log("localStorage set logout!");
    let today = new Date().toLocaleDateString();
    console.log(today);
    const data = {
      memberToken: cookie.getCookie("access_token"),
    }

    let confirmYn = window.confirm('로그아웃 하시겠습니까?');
    if(confirmYn) {
      await axios.post(LOOUT_URL, data).then((response) => {
        console.log("RES = %j" , response);
        console.log(response.data);
        let rsCode = response.data.code;

        if(rsCode === 200){
          window.localStorage.clear();
          window.localStorage.setItem("logout", today);
          cookie.removeCookie("access_token");
          cookie.removeCookie("memberNo");
          cookie.removeCookie("memberId");
          cookie.removeCookie("memberName");
          cookie.removeCookie("gubun");
          cookie.removeCookie("authList");
          window.location.href = "/";
        } else {
          alert('logOut Failed');
        }
      }).catch((err) => {
        alert('logOut Failed');
        console.log(err);
      })
    }

  }

  const handleClose = (e) => {
    e.preventDefault();
    setShowNav(!showNav);
    console.log(showNav);
  }

  const openChatRoomList = () => {
    window.open(
        `/messenger/list`,
        '_blank', // 새 창을 새 탭에서 열기
        //'width=386,height=602' // 새 창의 크기 설정 (선택 사항)
        'width=386,height=950' // 새 창의 크기 설정 (선택 사항)
    );
  }

  return (
    <>
      <h1>
        <Link to="/dashboard">
          <img src="/img/top_logo.png" alt="" />
        </Link>
      </h1>
      <div className="gnb">
        {/* {menuAuth && menuAuth.map((item, index) => (
          <div>{item}</div>
        ))}  */}

        {menuAuth.map(({ name, path, menu }, index) => (
          <HeaderMenu
            index={index}
            //auth={auth}
            key={index}
            name={name}
            path={path}
            isActive={menuStr === menu ? true : false} // 현재 URL pathname과 객체에 담긴 path값 일치 여부 확인
          />
        ))}
      </div>

      <div className={showNav ? "my on" : "my"}>
        <div className="link_in">
          <Link to="" className="link" onClick={ handleClose } >
          <img src="/img/man.png" alt="" /> {cookie.getCookie("memberName")}
          </Link>
          <ul className="smenu">
            {/* <li className="line">
              <Link to="" onClick={openAddHandle}>정보수정</Link>
            </li> */}
            <li>
              <Link to="#" onClick={logout}>로그아웃</Link>
              {/*<Link to="#" onClick={openChatRoomList}>메신저</Link>*/}
            </li>
          </ul>
        </div>
      </div>

      <div>
      <InfoModal
        ref={childRef}
        //datas={posts}
      />
      </div>
    </>
  );
};

export default Header;
